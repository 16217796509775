angular.module('puenteMontajes.directives', [])

.directive('isLoaded', function() {
    return{
        scope: false,
        restrict: 'A',
        link: function (scope, elements, arguments){ 
            if (scope.$last) {
                scope.$emit('content-changed');
            }
        }   
    }
})

.directive('swiper', function() {
    return {
        link: function(scope, element, attr) {
            scope.$on('content-changed', function() {
                new Swiper (element, {
                    // loop: true,
                    autoplay: 5000,
                    pagination: '.swiper-pagination',
                    // paginationType: 'custom',
                    nextButton: '.swiper-button-next',
                    prevButton: '.swiper-button-prev',
                    observer: true,
                    observeParents: true,
                    onInit: function(){console.log('start');}
                });
            });
        }
    };
});