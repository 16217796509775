angular.module('puenteMontajes.services', [])

.factory('Servicio', function() {

    return '/service/';

})

.factory('Producto', function($http, Servicio) {

    var producto = {}; 
    var productos_url = 'productos';

    producto.obtenerUno = function(id) {

        return $http.get(Servicio + productos_url + '/' + id).then(function(productos){
            return productos.data;
        });
    }

    producto.obtenerTodos = function() {

        return $http.get(Servicio + productos_url).then(function(productos){
            return productos.data;
        });
    }

    producto.obtenerPorCategoria = function(tipo) {

        return $http.get(Servicio + productos_url + '/categoria/' + tipo).then(function(productos){
            return productos.data;
        });
    }
 
     producto.buscar = function(keywords) {

        url = {
            url: Servicio + 'buscar', 
            method: "GET",
            params: {keywords: keywords}
        };

        return $http(url).then(function(productos){
            return productos.data;
        });
    }

    return producto;   
})

.factory('Categoria', function($http, Servicio) {

    var categoria = {}; 
    var categorias_url = 'categorias';

    categoria.obtenerTodos = function(super_tipo) {

        return $http.get(Servicio + categorias_url + '/tipo/' + super_tipo).then(function(categorias){
            return categorias.data;
        });
    }
 
    return categoria;   
})

.factory('Noticia', function($http, Servicio) {

    var noticia = {}; 
    var noticias_url = 'noticias';

    noticia.obtenerTodos = function(cantidad, current_page) {

        return $http.get(Servicio + noticias_url + '/cantidad/' + cantidad + '?page=' + current_page).then(function(noticias){
            return noticias.data;
        });
    }
 
    return noticia;   
})

.factory('Destacado', function($http, Servicio) {

    var destacado = {}; 
    var destacados_url = 'destacados';

    destacado.obtenerTodos = function() {

        return $http.get(Servicio + destacados_url).then(function(destacados){
            return destacados.data;
        });
    }
 
    return destacado;   
})

.factory('Usuario', function($http, Servicio) {

    var usuario = {}; 
    var usuarios_url = 'usuarios';

    var datos = {};

    usuario.setDatos = function(datos) {
        this.datos = datos;
    }

    usuario.validar = function(datos) {

        url = {
            url: Servicio + usuarios_url, 
            method: "GET",
            params: datos
        };

        return $http(url).then(function(result){
            return result.data.status;
        });
    }

    usuario.contacto = function(formData) {
        
        url = {
            method  : 'GET',
            url     : '/enviar/email',
            params  : formData
        };
        return $http(url).then(function(result){
            if (result.data.success) {
                return true;
            } else {
             
                return false;
            }
        });    
    }

    return usuario;   
});