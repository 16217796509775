angular.module( 'puenteMontajes', ['ui.router', 'puenteMontajes.controllers', 'angulartics', 'angulartics.google.analytics'])

.run(['$rootScope', '$state', '$stateParams', function ($rootScope, $state, $stateParams) {

    $rootScope.$state = $state;
    $rootScope.$stateParams = $stateParams;

}])

.config(function($stateProvider, $urlRouterProvider, $sceDelegateProvider, $locationProvider) {

	$locationProvider.html5Mode(true);
	$urlRouterProvider.otherwise('home');

	$stateProvider


	.state('home', {
		url: '/home',
		templateUrl: 'views/home.html',
		controller: 'HomeCtrl'
	})

	.state('distribucionElectrica', {
		url: '/distribucion-electrica',
		templateUrl: 'views/distribucion-electrica.html',
		controller: 'DistribucionElectricaCtrl'
	})

	.state('controlYAutomatizacion', {
		url: '/control-y-automatizacion',
		templateUrl: 'views/control-y-automatizacion.html',
		controller: 'ControlYAutomatizacionCtrl'
	})

	.state('producto', {
		url: '/producto/:slug',
		templateUrl: 'views/product.html',
		controller: 'ProductoCtrl'
	})

	.state('servicios', {
		url: '/servicios',
		templateUrl: 'views/services.html',
		controller: 'ServiciosCtrl'
	})

	.state('lighting', {
		url: '/lighting',
		templateUrl: 'views/ge-lighting.html',
		controller: 'ServiciosCtrl'
	})

	.state('acercaDe', {
		url: '/acerca-de',
		templateUrl: 'views/about.html',
	})

	.state('contacto', {
		url: '/contacto?{subject}',
		templateUrl: 'views/contact.html',
		controller: 'ContactCtrl'
	})

	.state('noticias', {
		url: '/noticias/:pagina',
		templateUrl: 'views/news.html',
		controller: 'NoticiasCtrl'
	})

	.state('buscador', {
		url: '/buscador?{keywords}',
		templateUrl: 'views/search.html',
		controller: 'BuscadorCtrl'
	})

});