angular.module('puenteMontajes.controllers', ['puenteMontajes.services', 'puenteMontajes.directives', 'ngSanitize'])

.controller('MainCtrl', function($scope, $state, Destacado, Categoria, Usuario) {

	$scope.productosDE = [];
	$scope.productosCYA = [];

	Categoria.obtenerTodos('Distribución Eléctrica').then(function(productosDE){
		$scope.productosDE = productosDE;
	});

	Categoria.obtenerTodos('Control y Automatización').then(function(productosCYA){
		$scope.productosCYA = productosCYA;
	});

	$scope.searchClickHandler = function(){
		var container = document.querySelector('.btns-container');
		var distribuidores = document.querySelector('.distribuidores-btn');
		if(container.classList.contains('hide')){
			distribuidores.classList.add('hidden');
			container.classList.remove('hide');
		}else{
			var input = document.querySelector('.search-input-top');
			if(input.value != ""){
				var form = document.querySelector('.search-form-top');
				form.submit();
			}
			container.classList.add('hide');
			distribuidores.classList.remove('hidden');
		}
	}
	$scope.openMobileMenu = function(){

		var body = document.querySelector('body');
		if(body.classList.contains('menu-open')){
			body.classList.remove('menu-open');
		}else{
			body.classList.add('menu-open');
		}
	};
	$scope.closeMenu = function(){
		var body = document.querySelector('body');
		body.classList.remove('menu-open');
	};

	$scope.openDistribuidores = function(){
		var body = document.querySelector('body');
		body.classList.add('show-login');
	};

	$scope.closeDistribuidores = function(mensaje){
		var body = document.querySelector('body');
		body.classList.remove('show-login');
		var loginHtml = document.querySelector('#login-container');
		if(loginHtml.classList.contains('errors')){
			loginHtml.classList.remove('errors');
		}
	};

	$scope.login = function(){

		var data = {
			username: $scope.username,
			password: $scope.password
		}

       	Usuario.validar(data).then(function(response){
			if(response){
				$scope.closeDistribuidores();
				$state.go('noticias', {pagina: 1}, {notify: true});
				Usuario.setDatos(data);
			} else {
				var loginHtml = document.querySelector('#login-container');
				loginHtml.classList.add('errors');
			}
		});
	}

})

.controller('HomeCtrl', function($scope, Destacado) {

	$scope.destacados = [];

	setBulletsHeight('bullet-data-container', 20);

	window.addEventListener("resize", function(){
		resizeThrottler('bullet-data-container', 20);
	}, false);

	Destacado.obtenerTodos().then(function(destacados){
		$scope.destacados = destacados;
	});

})

.controller('DistribucionElectricaCtrl', function($scope, $sce, Categoria) {

	var super_tipo = 'Distribución Eléctrica';


	$scope.categorias = [];

	Categoria.obtenerTodos(super_tipo).then(function(categorias){
		$scope.categorias = categorias;
	});

	//feo pero no encuentro solucion
  	setTimeout(function() {
    	setBulletsHeight('product-class', 0);
	}, 800);


	window.addEventListener("resize", function(){
		resizeThrottler('product-class', 0);
	}, false);

})

.controller('ControlYAutomatizacionCtrl', function($scope, $sce, Categoria) {

	var super_tipo = 'Control y Automatización';


	$scope.categorias = [];

	Categoria.obtenerTodos(super_tipo).then(function(categorias){
		$scope.categorias = categorias;
	});

	//feo pero no encuentro solucion
  	setTimeout(function() {
    	setBulletsHeight('product-class', 0);
	}, 800);
	window.addEventListener("resize", function(){
		resizeThrottler('product-class', 0);
	}, false);

})

.controller('NoticiasCtrl', function($scope, $state, $stateParams, Noticia, Usuario) {

	$scope.noticias = [];
	$scope.cantidad_paginas = 1;
	$scope.next_page = 1;
	$scope.prev_page = 1;
	$scope.current_page = $stateParams.pagina;

	var noticias_por_pagina = 5;




	Noticia.obtenerTodos(noticias_por_pagina,$scope.current_page).then(function(noticias){
		$scope.noticias = noticias.data;
		$scope.current_page = noticias.current_page;
		$scope.next_page = noticias.current_page + 1;
		$scope.prev_page = noticias.current_page - 1;
		$scope.last_page = noticias.last_page;
	});

	$scope.cantidadPaginas = function(cantidad_paginas) {
	    aplicarEstilosBtns();
	    return new Array(cantidad_paginas);
	}

	$scope.irAPagina = function(pagina) {
		if (pagina !== 0 && pagina <= $scope.last_page) {
			$state.go('noticias', {pagina: pagina}, {notify: true});
			aplicarEstilosBtns();
		}
	}

	aplicarEstilosBtns = function(){

		var paginador = angular.element( document.querySelector( '#paginador-'+$scope.current_page ) );
		var paginadores = angular.element( document.querySelector( '.paginadores' ) );
	    paginadores.removeClass('active');
	    paginador.addClass('active');

		var prev = angular.element( document.querySelector( '.prev' ) );
		var next = angular.element( document.querySelector( '.next' ) );

	    if ($scope.current_page == 1) {
		    prev.removeClass('active');
	    } else {
	    	prev.addClass('active');
	    }

	    if ($scope.current_page == $scope.last_page) {
		    next.removeClass('active');
	    } else {
	    	next.addClass('active');
	    }
	}

	checkLogin = function() {
       	Usuario.validar(Usuario.datos).then(function(response){
			if( ! response){
				$state.go('home', {notify: true});
			}
		});
	}

})

.controller('ServiciosCtrl', function($scope, $stateParams) {



})

.controller('ContactCtrl', function($http, $scope, $stateParams, Usuario) {

	$scope.subject = $stateParams.subject;

	console.log('ContactCtrl::');

	$scope.processForm = function() {

		console.log('processForm init');

		if($scope.contactForm.$valid){

			console.log('contactForm.$valid true');

			var formData = {
				fullName: $scope.fullName,
				company: $scope.company,
				segment: $scope.segment,
				email: $scope.email,
				phone: $scope.phone,
				address: $scope.address,
				location: $scope.location,
				province: $scope.province,
				subject: $scope.subject,
				message: $scope.message
			};

	       	Usuario.contacto(formData).then(function(response){
				var elmnt;
				if(response){
					elmnt = document.querySelector( '.success');
				}else{
					elmnt = document.querySelector( '.error');
				}
				elmnt.classList.remove('hide');
			});
		}else{
			console.log('contactForm.$valid false');
		};
	};


})

.controller('ProductoCtrl', function($scope, $sce, $stateParams, $state, Producto) {

	var id = $stateParams.slug;
	var minCantImgs = 3;

	Producto.obtenerUno(id).then(function(producto){
		$scope.producto = producto;

		producto.imagenes = pushImgsTransparentes(producto.imagenes);

		if ($scope.producto.tipo_producto.super_tipo == 'Distribución Eléctrica') {
			$scope.super_tipo = 'distribucion-electrica';
		} else {
			$scope.super_tipo = 'control-y-automatizacion';
		}

		Producto.obtenerPorCategoria(producto.tipo_producto.tipo).then(function(productos){
			$scope.productos = productos;

			// Busca la posición del actual elemento en el listado de productos
			$scope.productIndex = productos.map(function(producto) {
				return producto.id;
			}).indexOf(producto.id);

			aplicarEstilosBtns();
		});
	});

	// Lógica boton next
	$scope.productoSiguiente = function() {
		if ($scope.productIndex < $scope.productos.length - 1){
			$scope.productIndex++;
			cambiarProducto();
		}
	};

	// Lógica boton prev
	$scope.productoAnterior = function() {
		if ($scope.productIndex != 0){
			$scope.productIndex--;
			cambiarProducto();
		}
	};

	// Renderiza campo como HTML aplicando las clases correspondientes
	$scope.trustAsHtml = function(string) {
    	return $sce.trustAsHtml(string);
	};

	// Pasa a siguiente/anterior producto
	cambiarProducto = function() {
		$scope.producto = $scope.productos[$scope.productIndex];
		$scope.producto.imagenes = pushImgsTransparentes($scope.producto.imagenes);
		// $state.go('producto', {id: $scope.producto.id}, {notify: false});
		$state.go('producto', {slug: $scope.producto.slug}, {notify: false});
		aplicarEstilosBtns();
	}

	// Verifica la posicion del producto en el listado y aplica los estilos a los btns next y prev
	aplicarEstilosBtns = function(){

		var prevBtn = angular.element( document.querySelector( '#prev-btn' ) );
		var nextBtn = angular.element( document.querySelector( '#next-btn' ) );

		if ($scope.productIndex == 0) {
			prevBtn.addClass('disabled');
		} else {
			prevBtn.removeClass('disabled');
		}

		if ($scope.productIndex == $scope.productos.length - 1) {
			nextBtn.addClass('disabled');
		} else {
			nextBtn.removeClass('disabled');
		}
	}

	pushImgsTransparentes = function(imagenes) {

		if (imagenes.length < minCantImgs && imagenes.length > 0) {

			var image = {};

			for (i = imagenes.length; i < minCantImgs; i++) {
				imagen = {
					file: "transparent.png",
					id: i
				}
				imagenes.push(imagen);
			}
		}

		return imagenes;
	}

	$scope.openBox = function($event){
		var elmnt = $event.target,
			elmnts = document.getElementsByClassName('img-thumbnail'),
			srcs = [elmnt.getAttribute("data-img")],
			index = 0;
		if(elmnt.classList.contains('noImage')){
			return false;
		}
		for (var i = 0; i < elmnts.length; i++) {
			var src = elmnts[i].getAttribute("data-img");
			srcs.push(src);
			if(src === elmnt.getAttribute("data-img")){
				index = i+1;
			}
		};

		$.fancybox(srcs, {
			index: index,
		});
	}

})

.filter('pathThumb', function(){
	return function(text){
		var str = text.replace('/','/th_');
		return str;
	};
})

.controller('BuscadorCtrl', function($scope, $stateParams, Producto) {

	$scope.keywords = $stateParams.keywords;

	$scope.productos = [];

	Producto.buscar($scope.keywords).then(function(productos){
		$scope.productos = productos;
		$scope.cantidadProgramas = $scope.productos.length;
	});

});

// setea a todos los bullets el mismo tamaño
function setBulletsHeight(elmntClass, offset){


	var bulletsDataContainer = document.getElementsByClassName(elmntClass),//obtengo los bullets
		bulletsLength = bulletsDataContainer.length,//obtengo cuantos son
		biggestHeight = 0;//var para guarda el mayor tamaño
		var resize = checkWindowWidth();//chequea si se esta viendo en mobile para setear el tamaño en auto
		if(resize === false){
			for (var i = bulletsLength - 1; i >= 0; i--) {
				bulletsDataContainer[i].style.height ='auto';//al ser mobile se setea el tamaño en auto
			};
			return false;//se corta la ejecucion del metodo
		}

		for (var i = bulletsLength - 1; i >= 0; i--) {
			bulletsDataContainer[i].style.height = 'auto';//se setea en auto para obtener el tamaño que ocupa por defecto

			if(bulletsDataContainer[i].offsetHeight >= biggestHeight){//si el que estoy recorriendo es mas grande que el que esta guardado cambia el valor de la variable biggestheight
				biggestHeight = bulletsDataContainer[i].offsetHeight;
			}
		};

		for (var i = bulletsLength - 1; i >= 0; i--) {//setea el mismo tamaño a todos
			bulletsDataContainer[i].style.height = (biggestHeight - offset) + 'px';
		};

}

// obtiene el tamaño del window y chequea si es menor a 720
function checkWindowWidth(){
	var body=document.getElementsByTagName('body')[0],
	width = window.innerWidth || document.documentElement.clientWidth || body.clientWidth;
	if(width < 720){
		return false;
	}else{
		return true;
	}
}

// throttler para el evento resize asi no se ejecuta constantemente cuando hay resize
var resizeTimeout;
function resizeThrottler(elmntClass, offset) {
	if ( !resizeTimeout ) {
		resizeTimeout = setTimeout(function() {
			resizeTimeout = null;
			setBulletsHeight(elmntClass, offset);
		}, 400);
	}
}
